import Vue from 'vue';
import _laxios from '../../_laxios';

export default {
	namespaced: true,
	state: {
		base: {},
	},
	actions: {
		fetchByBrand: ({commit, rootGetters}, {category: category_id = 1, brand: marka_id, active= 1} = {}) => {
			let {'lang/id': langId} = rootGetters;
			const params = {lang_id: langId, category_id, marka_id, active};

			return _laxios.modelsForBrand_newAuto.request({params})
				.then((response = []) => {
					if (response && response.length) {
						let result = response.reduce((acc, item = {}) => {
							let { model_id } = item;
							item.marka_id = marka_id;
							acc[model_id] = item;
							return acc;
						}, {});
						commit('save', result)					}
				})
				.catch(()=> console.error('Ошибка. модели. новые'))
		},
	},
	mutations: {
		save(state, payload = {}) {
			Object
				.keys(payload)
				.forEach(key => Vue.set(state.base, key, payload[key]));
		},
		suggest(state, payload) {
			state.suggests.splice(0, state.suggests.length, ...payload)
		}
	},
	getters: {
		getAll: (state) => state.base,
		getByBrand(state, getters, rootState) {
			return function (brand) {
				return brand ?
					Object.keys(state.base).reduce((result, key) => {
						const {[key]: {marka_id: itemBrand} = {}} = state.base;
						if (itemBrand === brand) {
							result[key] = state.base[key]
						}
						return result;
					}, {})
					: state.base
			}
		},
	}
}


