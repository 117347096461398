import Vue from 'vue';
import _laxios from '../_laxios';
import getPageMeta from '../../helpers/getPageMeta';
import { get } from '../../helpers/cookie';

export default {
  namespaced: true,
  modules: {
    insurance: require('./modules/insurance').default,
    autotest: require('./modules/autotest').default,
    analytic: require('./modules/analytic').default,
    vin: require('./modules/vin').default,
    testNewFeaturesCookie: require('./testNewFeaturesCookie').default,
    testFormCookie: require('./testFormCookie').default,
  },
  state: {
    withVinPercent: undefined,
    countersPerDayHour: {},
    countersTotal: {},
    rand1: -1,
    rand2: -1,
    rand5: -1,
    accountInfo: {},
    segment: '',
    prevPointAction: undefined,
    GDPR: false,
    personalManager: {},
    carPrices: {},
    fastSearchLinks: [],
  },
  actions: {
    fetchFastSearchLinks({ commit, rootGetters }) {
      const { 'lang/id': langId } = rootGetters;

      return _laxios
        .fastSearchLinks
        .request({ params: { langId } })
        .then((links) => {
          commit('fastSearchLinks', links);
          return links;
        });
    },
    autoOrderSendToCM({}, data) {
      return _laxios
        .autoOrderSendToCM
        .request({ data })
        .catch((e) => {
          console.error('ERROR:::autoOrderSendToCM', e.message);
        });
    },
    sendCallBackRequest({}, { triggerId, data }) {
      return _laxios
        .sendCallBackRequest
        .expandUrl({ triggerId })
        .request({ data })
        .catch((e) => {
          console.error('ERROR:::sendCallBackRequest', e.message);
        });
    },
    withVinPercent({ commit }) {
      return _laxios.activeMotorcarsWithVIN.request()
        .then((count) => {
          // eslint-disable-next-line max-statements-per-line
          if (count) { commit('withVinPercent', count); }
        })
        .catch(() => console.error('Ошибка при загрузке счетчика процент авто с ВИНом'));
    },
    callTrackingAccountInfo({ commit }) {
      return _laxios.callTrackingAccountInfo.request()
        .then((data) => {
          // eslint-disable-next-line max-statements-per-line
          if (data) { commit('callTrackingAccountInfo', data); }
        }).catch((e) => {
          console.error('callTrackingAccountInfo', e.message);
        });
    },
    countersPerDayHour({ commit, rootGetters, getters }) {
      const { 'lang/id': langId } = rootGetters;
      const params = { langId };
      _laxios.countersPerDayHour.stay = () => Object.values(getters.countersPerDayHour).length;
      return _laxios.countersPerDayHour.request({ params })
        .then((body) => {
          if (body) { commit('countersPerDayHour', body); }
        })
        .catch(() => console.error('Ошибка при загрузке счетчиков за час/день'));
    },
    countersTotal({ commit, rootGetters, getters }, params = {}) {
      const { 'lang/id': langId } = rootGetters;
      params = { langId, ...params };
      _laxios.countersTotal.stay = () => Object.values(getters.countersTotal).length;
      return _laxios.countersTotal.request({ params })
        .then((body) => {
          if (body) { commit('countersTotal', body); }
        })
        .catch(() => console.error('Ошибка при загрузке общих счетчиков'));
    },
    subscribe({ commit, rootGetters, getters }, payload = {}) {
      const data = {
        ...payload,
        data: JSON.stringify(getPageMeta({ rootGetters }, {
          person_id: payload.userId, text: payload.text, name: payload.name, email: payload.email,
        })),
      };
      return _laxios.subscribe.request({ data }).catch((e) => {
        console.error('subscribe', e.message);
      });
    },
    sendToKayako({ commit, rootGetters, getters }, payload = {}) {
      const data = {
        ...payload, ...getPageMeta({ rootGetters }),
      };

      return _laxios.sendToKayako.request({ data });
    },
    // @todo переїхати на цей екшн всюди де потрібен сегмент
    loadSegment({ commit, rootGetters }, Cookie) {
      const headers = {};
      if (Cookie) {
        headers.Cookie = Cookie;
      }

      return _laxios.segment
        .request({ headers })
        .then((payload) => {
          commit('segment', payload);
        })
        .catch((e) => console.log(e, 'store/other:loadSegment'));
    },
    publicationDiscountsList({ rootGetters }, { recommendedDiscount }) {
      const { 'lang/id': langId } = rootGetters;
      const params = { langId, recommendedDiscount };
      return _laxios.publicationDiscountsList.request({ params }).catch((e) => {
        console.error('publicationDiscountsList', e.message);
      });
    },
    createRefillOrder({}, data) {
      return _laxios.createRefillOrder.request({ data }).catch((e) => {
        console.error('createRefillOrder', e.message);
      });
    },
    createServiceOrder({}, payload) {
      const { target = '', params = {}, data = {} } = payload;

      return _laxios.createServiceOrder
        .expandUrl({ target })
        .request({ params, data })
        .catch((e) => {
          console.error('createServiceOrder', e.message);
        });
    },
    makeOrder({ rootGetters }, data) {
      const { 'lang/id': langId } = rootGetters;
      const params = { langId };

      return _laxios.makeOrder
        .request({ params, data })
        .catch((e) => {
          console.error('makeOrder', e.message);
        });
    },
    startApplePaymentSession({}, data) {
      return _laxios.startApplePaymentSession.request({ data }).catch((e) => {
        console.error('startApplePaymentSession', e.message);
      });
    },
    checkPromoCode({}, params) {
      return _laxios.checkPromoCode.request({ params }).catch((e) => {
        console.error('checkPromoCode', e.message);
      });
    },
    getUserPromoCodes({}) {
      return _laxios.getUserPromoCodes.request().catch((e) => {
        console.error('getUserPromoCodes', e.message);
      });
    },
    getPromotions() {
      return _laxios.promotions
        .request()
        .then((payload) => payload)
        .catch((e) => console.log(e, 'store/other:getPromotions'));
    },
    setPrevPointAction({ commit }, payload) {
      commit('prevPointAction', payload);
    },
    callTracking({}, params) {
      return _laxios.callTracking
        .request({ params })
        .then((payload) => payload).catch((e) => {
          console.error('callTracking', e.message);
        });
    },
    setGDPR({ commit }) {
      // action який перевіряє чи є кука gdpr. Блок інклудиться за допомогою ssi
      // Юзається для показу модалки після прийняття gdpr
      if (_TARGET_ === 'client') {
        if (get('gdpr')) {
          commit('setGDPR', true);
        } else {
          const el = document.querySelector('#gdpr-notifier');
          if (el) {
            el.addEventListener('click', () => {
              if (get('gdpr')) {
                commit('setGDPR', true);
              }
            });
          }
        }
      }
    },
    /** @deprecated 30.10.2024 */
    autoDealerBilling({ commit }, data) {
      return _laxios.autoDealerBilling.request({ data }).catch((e) => {
        console.error('autoDealerBilling', e.message);
      });
    },
    getPersonalManager({ commit, rootGetters }) {
      const { 'lang/id': langId } = rootGetters;
      return _laxios.getPersonalManager
        .request({ params: { langId } })
        .then((data) => {
          commit('setPersonalManager', data);
          return data;
        })
        .catch((e) => console.error(e));
    },
    reCallToMe({ commit }, data) {
      return _laxios.reCallToMe
        .request({ data })
        .then((payload) => payload)
        .catch((e) => console.error(e));
    },
    sendToCreatio({ commit }, data) {
      return _laxios.sendToCreatio
          .request({ data })
          .then((payload) => payload)
          .catch((e) => console.error(e));
    },
    dealersLogo({ commit }, params) {
      return _laxios.dealersLogo
        .request({ params })
        .then((data) => data)
        .catch((e) => console.error(e));
    },
    newAutoInformer({ commit, rootGetters }, { sort, params }) {
      const { 'lang/id': langId } = rootGetters;
      return _laxios.newAutoInformer
        .expandUrl({ sort })
        .request({ params: { lang_id: langId, ...params } })
        .then((data) => data)
        .catch((e) => console.error('ERROR:::newAutoInformer', e.message));
    },
    similarAds({ commit, rootGetters }, { advertisementId, params }) {
      return _laxios.similarAds
        .expandUrl({ advertisementId })
        .request({ params })
        .then((data) => data)
        .catch((e) => console.error('ERROR:::similarAds', e.message));
    },
    carPrices({ commit, getters }, params) {
      if (!getters.getCarPrice(params.model_id)) {
        return _laxios.carPrices
          .request({ params })
          .then((data) => {
            commit('setCarPrice', { key: params.model_id, data });
            return data;
          })
          .catch((e) => console.error('ERROR:::carPrices', e.message));
      }
    },
    uploadImage({}, data) {
      return _laxios.uploadImage
        .request({ data })
        .then((result) => result)
        .catch((e) => console.error('ERROR:::uploadImage', e.message));
    },
    async setСommentToСall({}, data) {
      try {
        const response = await _laxios.postСommentToСall.request({ data });
        return response;
      } catch (e) {
        console.error('ERROR:::setСommentToСall', e);
        return null;
      }
    },
  },
  mutations: {
    fastSearchLinks(state, links) {
      Vue.set(state, 'fastSearchLinks', links);
    },
    countersPerDayHour(state, payload = {}) {
      let { hourly, daily, vin } = payload;
      hourly = hourly && hourly !== '0' ? hourly : undefined;
      daily = daily && daily !== '0' ? daily : undefined;
      vin = vin && vin !== '0' ? vin : undefined;
      Vue.set(state, 'countersPerDayHour', { hourly, daily, vin });
    },
    countersTotal(state, payload) {
      Vue.set(state, 'countersTotal', payload);
    },
    withVinPercent(state, data) {
      Vue.set(state, 'withVinPercent', data);
    },
    callTrackingAccountInfo(state, data) {
      Vue.set(state, 'accountInfo', data);
    },
    segment(state, data) {
      const { segment = '' } = data;
      Vue.set(state, 'segment', segment);
    },
    prevPointAction(state, data) {
      Vue.set(state, 'prevPointAction', data);
    },
    setGDPR(state, data) {
      Vue.set(state, 'GDPR', data);
    },
    setPersonalManager(state, data) {
      Vue.set(state, 'personalManager', data);
    },
    setCarPrice(state, { key, data }) {
      Vue.set(state.carPrices, key, data);
    },
  },
  getters: {
    fastSearchLinks: ({ fastSearchLinks }) => fastSearchLinks,
    countersPerDayHour: ({ countersPerDayHour }) => countersPerDayHour,
    countersTotal: ({ countersTotal }) => countersTotal,
    withVinPercent: ({ withVinPercent }) => withVinPercent,
    callTrackingAccountInfo: ({ accountInfo }) => accountInfo,
    rand1: (state) => (state.rand1 > -1 ? state.rand1 : state.rand1 = Math.floor(Math.random() * 2)),
    rand2: (state) => (state.rand2 > -1 ? state.rand2 : state.rand2 = Math.floor(Math.random() * 3)),
    rand5: (state) => (state.rand5 > -1 ? state.rand5 : state.rand5 = Math.floor(Math.random() * 6)),
    segment: ({ segment }) => segment,
    prevPointAction: ({ prevPointAction }) => prevPointAction,
    GDPR: ({ GDPR }) => GDPR,
    personalManager: ({ personalManager }) => personalManager,
    getCarPrice(state) {
      return (id) => state.carPrices[id];
    },
  },
};
