<template>
  <div
      v-if="isShow"
      class="container">
    <div class="switching-versions">
      <div class="content">
        <svg class="svg size20" width="20" height="20" viewBox="0 0 20 20" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <circle cx="10" cy="10" r="10" fill="#79BE00"/>
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M15.7072 6.70712L8.00008 14.4142L4.29297 10.7071L5.70718 9.29291L8.00008 11.5858L14.293 5.29291L15.7072 6.70712Z"
                fill="white"/>
        </svg>
        <div class="content-main">
          {{ $t('В новому AUTO.RIA розглядати авто й дізнаватись про нього все стало ще легше. Переконайтесь!') }}
        </div>
      </div>
      <div class="btn-wrap">
        <button
            class="btn-blue blue size16"
            @click="goToNewVersion"
        >
          {{ $t('Перейти до нової версії') }}
        </button>
      </div>
      <button
          class="btn-close"
          @click="closeSwitchingVersions"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M19.295 6.115C19.6844 5.72564 19.6844 5.09436 19.295 4.705V4.705C18.9056 4.31564 18.2744 4.31564 17.885 4.705L12 10.59L6.115 4.705C5.72564 4.31564 5.09436 4.31564 4.705 4.705V4.705C4.31564 5.09436 4.31564 5.72564 4.705 6.115L10.59 12L4.705 17.885C4.31564 18.2744 4.31564 18.9056 4.705 19.295V19.295C5.09436 19.6844 5.72564 19.6844 6.115 19.295L12 13.41L17.885 19.295C18.2744 19.6844 18.9056 19.6844 19.295 19.295V19.295C19.6844 18.9056 19.6844 18.2744 19.295 17.885L13.41 12L19.295 6.115Z"
              fill="#414042"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>

const {get, set} = require('../../../helpers/cookie');

export default {
  name: "SwitchingVersions",

  data() {
    return {
      isShow: false,
    }
  },
  methods: {
    goToNewVersion(){
      set('go_to_old_version', 0, {expires: 31536000, path: "/;Secure;SameSite=None"})
      this._slonik({
        event_id: 1103,
        click_action: 1,
        block_type: 1,
        item_type: 2
      });
      location.reload()
    },
    closeSwitchingVersions() {
      set('user_closed_switch_new_version', 1, { expires: 259200, path: "/;Secure;SameSite=None"})
      this.isShow = false;
    }
  },
  mounted(){
    this.isShow = get('go_to_old_version') && Number(get('user_closed_switch_new_version')) !== 1
  }
}
</script>

<style lang="sass" src="../../../../sass/components/_switching-versions.sass"></style>
