import { render, staticRenderFns } from "./SwitchingVersions.vue?vue&type=template&id=f01ed3ee&"
import script from "./SwitchingVersions.vue?vue&type=script&lang=js&"
export * from "./SwitchingVersions.vue?vue&type=script&lang=js&"
import style0 from "../../../../sass/components/_switching-versions.sass?vue&type=style&index=0&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports